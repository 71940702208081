// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-historia-js": () => import("./../../../src/pages/historia.js" /* webpackChunkName: "component---src-pages-historia-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-metaka-165-js": () => import("./../../../src/pages/metaka165.js" /* webpackChunkName: "component---src-pages-metaka-165-js" */),
  "component---src-pages-metaka-180-js": () => import("./../../../src/pages/metaka180.js" /* webpackChunkName: "component---src-pages-metaka-180-js" */),
  "component---src-pages-metaka-200-js": () => import("./../../../src/pages/metaka200.js" /* webpackChunkName: "component---src-pages-metaka-200-js" */),
  "component---src-pages-metaka-240-jl-js": () => import("./../../../src/pages/metaka240JL.js" /* webpackChunkName: "component---src-pages-metaka-240-jl-js" */),
  "component---src-pages-metaka-240-js": () => import("./../../../src/pages/metaka240.js" /* webpackChunkName: "component---src-pages-metaka-240-js" */),
  "component---src-pages-metaka-300-js": () => import("./../../../src/pages/metaka300.js" /* webpackChunkName: "component---src-pages-metaka-300-js" */),
  "component---src-pages-metaka-320-jl-js": () => import("./../../../src/pages/metaka320JL.js" /* webpackChunkName: "component---src-pages-metaka-320-jl-js" */),
  "component---src-pages-yhteystiedot-js": () => import("./../../../src/pages/yhteystiedot.js" /* webpackChunkName: "component---src-pages-yhteystiedot-js" */)
}

